import React from "react";
import { DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import { useState, useEffect } from "react";
import moment from "moment/moment";


import { useMediaQuery } from "react-responsive";

const HORIZONTAL_ORIENTATION = "horizontal";
const VERTICAL_ORIENTATION = "vertical";

const cleanDates = (dates = {}) => {
    const calendarDates = [];

    for (const [key, value] of Object.entries(dates)) {
        if (value.availability === "unavailable") {
            calendarDates.push(moment(key));
        }
    }

    return calendarDates;
};

export default function Calendar({
    heading,
    listing,
    block_holiday,
    block_weekend,
    shoulder_only,
    stay_between_start_date,
    stay_between_end_date,
    discount_amount,
    average_price
}) {
    const [orientation, setOrientation] = useState();
    const [hasCalendar, setHasCalendar] = useState(false);
    const [calendarDates, setCalendarDates] = useState([]);


    const isSM = useMediaQuery({ minWidth: 700 });
    const isMD = useMediaQuery({ minWidth: 1024 });
    const isLG = useMediaQuery({ minWidth: 1240 });


    const [focusedInput, setFocusedInput] = useState('startDate'); // 'startDate' or 'endDate'

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [averagePrice, setAveragePrice] = useState(average_price);
    const [calendarData, setCalendarData] = useState([]);
    const [discountedPrice, setDiscountedPrice] = useState(null);

    const handleDayClick = ({ startDate, endDate }) => {
        if (focusedInput === 'startDate') {
            setSelectedStartDate(startDate);
            setFocusedInput('endDate'); // Switch focus to endDate after selecting startDate
        } else if (focusedInput === 'endDate') {
            setSelectedEndDate(endDate);
            const targetDiv = document.getElementById('startDateBox');
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleFocusChange = (focusedInput) => {
        if (!focusedInput) {
            // If no input is currently focused, default to focusing startDate
            setFocusedInput('startDate');
        } else {
            setFocusedInput(focusedInput);
        }
    };

    useEffect(() => {
        // Construct query parameters based on props
        const queryParams = new URLSearchParams({
            listing,
            block_holiday,
            block_weekend,
            shoulder_only,
            stay_between_start_date,
            stay_between_end_date,
        }).toString();

        const url = `https://guesty-api-proxy.sunflower-vacations.com/api/calendar?${queryParams}`;
        // const url = `http://localhost:3000/api/calendar?${queryParams}`;


        async function fetchData() {

            const data = await fetch(url);
            const json = await data.json();

            const dates = cleanDates(json.data);
            setCalendarDates(dates);
            setCalendarData(json.data);
        }

        setHasCalendar(true);

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedStartDate && selectedEndDate) {
            let sum = 0;
            let count = 0;

            let currentDate = moment(selectedStartDate);
            const endDate = moment(selectedEndDate);

            while (currentDate <= endDate) {
                const dateStr = currentDate.format("YYYY-MM-DD");
                // debugger
                if (calendarData[dateStr] && calendarData[dateStr].availability === "available") {
                    sum += calendarData[dateStr].daily_price;
                    count += 1;
                }
                currentDate = currentDate.add(1, 'days');
            }

            if (count > 0) {
                const avgPrice = sum / count;
                setAveragePrice(avgPrice);
            } else {
                setAveragePrice(null);
            }
        }
    }, [selectedStartDate, selectedEndDate, calendarData]);

    useEffect(() => {
        if (averagePrice != null && discount_amount != null) {
            const discountFactor = 1 - (discount_amount / 100);
            const discounted = discountFactor >= 0 ? averagePrice * discountFactor : 0; // Ensure discounted price can't be negative
            setDiscountedPrice(discounted);
        } else if (averagePrice != null) {
            // If there's no discount, the discounted price should be the average price
            setDiscountedPrice(averagePrice);
        }
    }, [averagePrice, discount_amount]);

    const isDayBlocked = (momentDate) => {
        return calendarDates.some((date) => date.isSame(momentDate, "day"));
    };

    const startDateString = selectedStartDate ? selectedStartDate.format('YYYY-MM-DD') : '';
    const endDateString = selectedEndDate ? selectedEndDate.format('YYYY-MM-DD') : '';
    const isBookButtonEnabled = () => selectedStartDate && selectedEndDate;

    const handleBook = () => {
        const finalPrice = (discountedPrice !== null && discountedPrice !== undefined) ? discountedPrice : averagePrice;
        const priceFormatted = finalPrice.toFixed(2); // Format the price to two decimal places
        const baseUrl = '/bookings/new';
        const queryParams = `?checkin_date=${startDateString}&checkout_date=${endDateString}&avg_price=${priceFormatted}`;
        window.location.href = baseUrl + queryParams;
    };

    const priceDisplay = () => {
        if (averagePrice == null) return null; // If there's no average price, don't display anything

        if (discount_amount > 0 && discountedPrice !== null && discountedPrice !== undefined) {

            // If there's a discount, show crossed-out average price and discounted price
            return (
                <div>
                    Average Daily Price: <del>${averagePrice.toFixed(2)}</del>
                    <span> ${discountedPrice.toFixed(2)} with discount</span>
                </div>
            );
        } else {
            // If there's no discount, show only the average price
            return (
                <div>Average Daily Price: ${averagePrice.toFixed(2)} {couponButton()}</div>
            );
        }
    };

    const couponButton = () => {
        return (
          <span className='text-xs underline text-red-800'><em><a href='/bookings/select_coupon'>Coupon?</a></em></span>
        );
    }

    return (
        <div id="calendar">
            <h3 className="mb-4 text-2xl">{heading}</h3>
            <div style={{marginBottom: 16}}>
                <input id="startDateBox" className='mx-2' type="text" name="start date" value={startDateString} readOnly
                       placeholder="Select Dates"/>
                <input className='mx-2' type="text" name="end date" value={endDateString} readOnly/>
                <button id="bookingButton" disabled={!isBookButtonEnabled()} onClick={handleBook}
                        className="px-6 group inline-flex items-center justify-center rounded-md py-3 px-6 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 text-white hover:text-slate-100 hover:bg-red-500 disabled:bg-gray-600 active:bg-red-800 active:text-yellow-100 focus-visible:outline-red-600 mt-6 bg-[#DB4327] transition-colors duration-150">
                    Book
                </button>
                {priceDisplay()}
            </div>
            {hasCalendar && (
                <DayPickerRangeController
                    numberOfMonths={isMD ? 3 : 2}
                    initialVisibleMonth={() => moment()}
                    isDayBlocked={isDayBlocked}
                    minDate={moment()}
                    orientation={isSM ? HORIZONTAL_ORIENTATION : VERTICAL_ORIENTATION}
                    maxDate={moment().add(18, "months")}
                    onDatesChange={handleDayClick}
                    minimumNights={2}
                    focusedInput={focusedInput} // necessary for managing focus
                    onFocusChange={handleFocusChange} // handle focus ch
                    startDate={selectedStartDate} // Pass the selectedStartDate as startDate
                    endDate={selectedEndDate} // Pass the selectedEndDate as endDate
                />
            )}
        </div>
    );
}
