import React, { useState } from 'react'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from "yet-another-react-lightbox/plugins/zoom";


import AppImage from './ui/AppImage'

import 'yet-another-react-lightbox/styles.css'

export default function Photos({ photos }) {
  const [index, setIndex] = useState(-1)

  return (
    <>
      <PhotoAlbum
        photos={photos}
        layout="columns"
        columns={2}
        renderPhoto={AppImage}
        onClick={(_event, _photo, index) => {
          setIndex(index)
        }}
      />
      <Lightbox
        slides={photos}
        open={index >= 0}
        plugins={[Zoom]}
        index={index}
        close={() => setIndex(-1)}
        animation={{
          fade: 400,
          slide: 0,
        }}
        carousel={{
          preload: 2,
          padding: 0,
          imageFit: 'cover',
        }}
        render={{
          slide: (image, _offset, rect) => {
            const width = Math.round(
              Math.min(rect.width, (rect.height / image.height) * image.width)
            )
            const height = Math.round(
              Math.min(rect.height, (rect.width / image.width) * image.height)
            )

            return (
              <div style={{ position: 'relative', width, height }}>
                <img
                  src={image.src}
                  loading="eager"
                  placeholder="blur"
                  alt={'alt' in image ? image.alt : ''}
                  sizes={
                    typeof window !== 'undefined'
                      ? `${Math.ceil((width / window.innerWidth) * 100)}vw`
                      : `${width}px`
                  }
                />
              </div>
            )
          },
        }}
      />
    </>
  )
}
